<template>
  <div class="terms-conditions">
    <p class="text-left">
      Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quis eaque vel
      assumenda facilis cupiditate provident est neque nobis voluptate error
      accusantium doloremque, dignissimos ducimus nam officia sed ex? A,
      possimus.
    </p>
    <p class="text-left">
      Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quis eaque vel
      assumenda facilis cupiditate provident est neque nobis voluptate error
      accusantium doloremque, dignissimos ducimus nam officia sed ex? A,
      possimus.
    </p>
  </div>
</template>
<script>
export default {};
</script>
